// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entryWrapperFooterBuc3fQh8lSneDlv7pHIy{align-items:center;background:hsla(0,0%,100%,.8);bottom:0;display:flex;justify-content:center;padding:12px;position:fixed;width:100%}.entryWrapperFootergy0Dne2ktOPYwinatHeJ{font-size:12px;font-weight:300;margin-right:3px}.entryWrapperFootervey5AQa5E9u_R733TM67{color:#049fd9;cursor:pointer;font-size:14px;font-weight:400;text-decoration:none}.entryWrapperFooterE3oYbZmVEkapEu89tyAW{font-size:12px;font-weight:300;margin:0 3px}`, "",{"version":3,"sources":["webpack://./src/components/entryWrapper/components/entryWrapperFooter/entryWrapperFooter.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,wCACI,kBAAA,CACA,6BAAA,CACA,QAAA,CACA,YAAA,CACA,sBAAA,CACA,YAAA,CACA,cAAA,CACA,UAAA,CAGJ,wCACI,cAAA,CACA,eAAA,CACA,gBAAA,CAGJ,wCACI,aCVI,CDWJ,cAAA,CACA,cAAA,CACA,eAAA,CACA,oBAAA,CAGJ,wCACI,cAAA,CACA,eAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var loginFooter = `entryWrapperFooterBuc3fQh8lSneDlv7pHIy`;
export var copyrightDescription = `entryWrapperFootergy0Dne2ktOPYwinatHeJ`;
export var footerLink = `entryWrapperFootervey5AQa5E9u_R733TM67`;
export var barrier = `entryWrapperFooterE3oYbZmVEkapEu89tyAW`;
export default ___CSS_LOADER_EXPORT___;
