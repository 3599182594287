// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.idleModalHrUtqNsqk5e3RDAkXhpS{align-items:center;background-color:#fff;display:flex;flex-direction:column;left:50%;margin:0 auto;padding:48px 80px;position:absolute;top:50%;transform:translate(-50%, -50%);width:500px}.idleModalK_1J3QtjGPpU4PIH_M4i{position:fixed;top:0;width:100%;height:100%;background:rgba(0,0,0,.7);z-index:1000}.idleModalJM3TSVk8qKM4Efm1W3KU{margin:10px 0 12px;width:100%;text-align:center;font-size:22px;font-weight:400;color:#58585b}.idleModalHA0T8sIfxqxxGgFCGG9j{text-align:center;line-height:1.5;font-size:15px;color:#58585b;font-weight:400;margin-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/components/idleModal/idleModal.scss","webpack://./src/styles/variables/_colors.scss"],"names":[],"mappings":"AAEA,+BACI,kBAAA,CACA,qBCJI,CDKJ,YAAA,CACA,qBAAA,CACA,QAAA,CACA,aAAA,CACA,iBAAA,CACA,iBAAA,CACA,OAAA,CACA,+BAAA,CACA,WAAA,CAGJ,+BACI,cAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,yBAAA,CACA,YAAA,CAGJ,+BACI,kBAAA,CACA,UAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,aC1Bc,CD6BlB,+BACI,iBAAA,CACA,eAAA,CACA,cAAA,CACA,aCjCc,CDkCd,eAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var contentWrapper = `idleModalHrUtqNsqk5e3RDAkXhpS`;
export var modalWrapper = `idleModalK_1J3QtjGPpU4PIH_M4i`;
export var title = `idleModalJM3TSVk8qKM4Efm1W3KU`;
export var description = `idleModalHA0T8sIfxqxxGgFCGG9j`;
export default ___CSS_LOADER_EXPORT___;
