// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noPageFoundsK1nbHP0qa4IIK6D8vfX{align-items:center;display:flex;flex-direction:column;height:100vh;justify-content:center;text-align:center}.noPageFoundsK1nbHP0qa4IIK6D8vfX p{font-size:30px;font-weight:bold}.noPageFoundsK1nbHP0qa4IIK6D8vfX p:not(:last-child){margin-bottom:15px}.noPageFoundzNUIZPtiKb9aCtIfWPxw{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/components/noPageFound/noPageFound.scss"],"names":[],"mappings":"AAEA,iCACI,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CAEA,mCACI,cAAA,CACA,gBAAA,CAGJ,oDACI,kBAAA,CAIR,iCACI,eAAA","sourceRoot":""}]);
// Exports
export var green7 = `#409618`;
export var red7 = `#d4503b`;
export var red8 = `#c24632`;
export var wrapper = `noPageFoundsK1nbHP0qa4IIK6D8vfX`;
export var btn = `noPageFoundzNUIZPtiKb9aCtIfWPxw`;
export default ___CSS_LOADER_EXPORT___;
