/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FETCH_STATUS } from '../../../../../../common/constants';
import apiV1 from '../../../../../../services/axiosConfig/apiV1';
import { updateAlertParams } from '../../../../../../slices/alert/alertSlice';
import { errorHandler } from '../../../../../../common/utils';

const initialState = {
    extendTrialStatus: FETCH_STATUS.INIT,
    extendTrialResult: {},
    getTrialDetailsStatus: FETCH_STATUS.INIT,
    getTrialDetailsResult: {},
    getTrialsStatus: FETCH_STATUS.INIT,
    getTrialsResult: {},
    editTrialPackageStatus: FETCH_STATUS.INIT,
    editTrialPackageResult: {},
    subscriptionDetailsState: FETCH_STATUS.INIT,
    subscriptionDetails: {},
    changeTrialStatusState: FETCH_STATUS.INIT,
    changeTrialStatusResult: {},
    // packages
    umbrellaPackagesStatus: FETCH_STATUS.INIT,
    sePackagesStatus: FETCH_STATUS.INIT,
    xdrPackagesStatus: FETCH_STATUS.INIT,
    aiDefensePackagesStatus: FETCH_STATUS.INIT,
    sccPackagesStatus: FETCH_STATUS.INIT,
    umbrellaPackages: [],
    sePackages: [],
    xdrPackages: [],
    aiDefensePackages: [],
    sccPackages: [],
};

export const editTrial = createAsyncThunk(
    'trial/edit',
    async (
        { payload, parentOrgId, trialId, isDelete },
        { rejectWithValue, dispatch },
    ) => {
        try {
            // add seats and adminemails in the payload
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}`,
                isDelete ? 'delete' : 'put',
                payload,
            );
        } catch (err) {
            dispatch(
                updateAlertParams({
                    message: errorHandler(err),
                    severity: 'error',
                }),
            );
            return rejectWithValue(err);
        }
    },
);

export const changeTrialStatus = createAsyncThunk(
    'trial/changeTrialStatus',
    async ({ status, parentOrgId, trialId }, { rejectWithValue, dispatch }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/${status}`,
                'put',
            );
        } catch (err) {
            dispatch(
                updateAlertParams({ message: err?.error, severity: 'error' }),
            );
            return rejectWithValue(err);
        }
    },
);

export const getPackages = createAsyncThunk(
    'organizations/getPackages',
    async ({ productType }, { rejectWithValue }) => {
        let url = `/bfg/packages`;

        if (productType) {
            url += `?productType=${productType}`;
        }

        try {
            const response = await apiV1.sendRequest(url, 'get');

            return { response: response?.data?.data, productType };
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getTrials = createAsyncThunk(
    'organizations/getCustomers',
    async ({ orgId, page, limit, search }, { rejectWithValue }) => {
        const requestString = new URLSearchParams();

        requestString.append('page', page);
        requestString.append('limit', limit);

        if (search) {
            requestString.append('search', search);
        }

        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${orgId}/customers?${requestString}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const extendTrial = createAsyncThunk(
    'trials/extendTrial',
    async ({ parentOrgId, trialId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/trialextensions`,
                'post',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const updateEndDate = createAsyncThunk(
    'trials/updateEndDate',
    async ({ parentOrgId, trialId, payload }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/trials/${trialId}/updateEndDate`,
                'put',
                payload,
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getCustomerDetails = createAsyncThunk(
    'trials/getCustomerDetails',
    async ({ parentOrgId, customerId }, { rejectWithValue }) => {
        try {
            return await apiV1.sendRequest(
                `/bfg/organizations/${parentOrgId}/customers/byCustomer/${customerId}`,
                'get',
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    },
);

export const getSubscriptionDetails = createAsyncThunk(
    'trials/getSubscriptionDetails',
    async ({ parentOrgId, trialId }, { rejectWithValue }) => {
        try {
            const response = await apiV1.sendRequest(
                `bfg/organizations/${parentOrgId}/trials/${trialId}/details`,
            );
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const packageStatusesHandler = (state, productType, status, response) => {
    switch (productType) {
        case 1: {
            state.umbrellaPackages = response || [];
            state.umbrellaPackagesStatus = status;
            break;
        }
        case 2: {
            state.sePackages = response || [];
            state.sePackagesStatus = status;
            break;
        }
        case 4: {
            state.xdrPackagesStatus = status;
            state.xdrPackages = response || [];
            break;
        }
        case 5: {
            state.aiDefensePackagesStatus = status;
            state.aiDefensePackages = response || [];
            break;
        }
        case 6: {
            state.sccPackagesStatus = status;
            state.sccPackages = response || [];
            break;
        }
        default:
            break;
    }
};

const trialsSlice = createSlice({
    name: 'trialsSlice',
    initialState,
    reducers: {
        clearTrialExtensionData(state) {
            return {
                ...state,
                extendTrialStatus: FETCH_STATUS.INIT,
                extendTrialResult: {},
            };
        },
        clearGetTrialsDetailsData(state) {
            return {
                ...state,
                getTrialDetailsStatus: FETCH_STATUS.INIT,
                getTrialDetailsResult: {},
            };
        },
        clearTrialDeleteData(state) {
            return {
                ...state,
                editTrialPackageResult: {},
                editTrialPackageStatus: FETCH_STATUS.INIT,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            // extend trial
            .addCase(extendTrial.pending, (state) => {
                state.extendTrialStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(extendTrial.fulfilled, (state, action) => {
                state.extendTrialStatus = FETCH_STATUS.SUCCESS;
                state.extendTrialResult = action.payload;
            })
            .addCase(extendTrial.rejected, (state, action) => {
                state.extendTrialStatus = FETCH_STATUS.ERROR;
                state.extendTrialResult = action.payload;
            })

            // get trial details
            .addCase(getCustomerDetails.pending, (state) => {
                state.getTrialDetailsStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getCustomerDetails.fulfilled, (state, action) => {
                state.getTrialDetailsStatus = FETCH_STATUS.SUCCESS;
                state.getTrialDetailsResult = action.payload;
            })
            .addCase(getCustomerDetails.rejected, (state, action) => {
                state.getTrialDetailsStatus = FETCH_STATUS.ERROR;
                state.getTrialDetailsResult = action.payload;
            })

            // get trials
            .addCase(getTrials.pending, (state) => {
                state.getTrialsStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(getTrials.fulfilled, (state, action) => {
                state.getTrialsStatus = FETCH_STATUS.SUCCESS;
                state.getTrialsResult = action.payload;
            })
            .addCase(getTrials.rejected, (state, action) => {
                state.getTrialsStatus = FETCH_STATUS.ERROR;
                state.getTrialsResult = action.payload;
            })

            // edit trials
            .addCase(editTrial.pending, (state) => {
                state.editTrialPackageStatus = FETCH_STATUS.FETCHING;
            })
            .addCase(editTrial.fulfilled, (state, action) => {
                state.editTrialPackageStatus = FETCH_STATUS.SUCCESS;
                state.editTrialPackageResult = action.payload;
            })
            .addCase(editTrial.rejected, (state, action) => {
                state.editTrialPackageStatus = FETCH_STATUS.ERROR;
                state.editTrialPackageResult = action.payload;
            })

            // get packages
            .addCase(getPackages.pending, (state, action) => {
                packageStatusesHandler(
                    state,
                    action?.meta?.arg,
                    FETCH_STATUS.FETCHING,
                );
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                packageStatusesHandler(
                    state,
                    action?.payload?.productType,
                    FETCH_STATUS.SUCCESS,
                    action?.payload.response,
                );
            })
            .addCase(getPackages.rejected, (state, action) => {
                packageStatusesHandler(
                    state,
                    action?.payload?.productType,
                    FETCH_STATUS.ERROR,
                );
            })

            // get subscription details
            .addCase(getSubscriptionDetails.pending, (state) => {
                state.subscriptionDetailsState = FETCH_STATUS.FETCHING;
            })
            .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
                state.subscriptionDetails = action?.payload;
                state.subscriptionDetailsState = FETCH_STATUS.SUCCESS;
            })
            .addCase(getSubscriptionDetails.rejected, (state) => {
                state.subscriptionDetailsState = FETCH_STATUS.ERROR;
            })

            // change status of trial (disable/enable)
            .addCase(changeTrialStatus.pending, (state) => {
                state.changeTrialStatusState = FETCH_STATUS.FETCHING;
            })
            .addCase(changeTrialStatus.fulfilled, (state, action) => {
                state.changeTrialStatusState = FETCH_STATUS.SUCCESS;
                state.changeTrialStatusResult = action?.payload;
            })
            .addCase(changeTrialStatus.rejected, (state) => {
                state.changeTrialStatusState = FETCH_STATUS.ERROR;
            });
    },
});

export const {
    clearTrialExtensionData,
    clearGetTrialsDetailsData,
    clearTrialDeleteData,
} = trialsSlice.actions;

export default trialsSlice.reducer;
